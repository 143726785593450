import React from 'react';
import {
  Box,
  Heading,
  Link,
  Text,
  Divider,
  HStack,
  Tag,
  SpaceProps,
  useColorModeValue,
  Container,
} from '@chakra-ui/react';


import techdeps from './data/tech_dependencies.json';
import CountryChart from './charts/CountryChart';
import TechDepsChart from './charts/TechDepsChart';
import TechChart from './charts/TechChart';
import Avatar from "boring-avatars";
import ExternalButton from './ExternalButton';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name?: string;
  link?: string;
}

const withLink = (link: string, children: React.ReactNode) => {
  if (!link) {
    return <>{children}</>;
  };

  return (
    <Link textDecoration="none" _hover={{ textDecoration: 'none' }} href={link} target="_blank">
      <HStack>
        {children}
      </HStack>
    </Link>
  );
};

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      {props.name && (withLink(props.link ?? '', <><Avatar
        size={40}
        name={props.name}
        variant="marble"
        colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
      /><Text fontWeight="medium">{props.name}</Text><Text>—</Text></>))}
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const ArticleList = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <Heading as="h1">LinkedIn Jobs Statistics Dec 2022</Heading>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between">
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <Heading marginTop="1">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              Overview
            </Link>
          </Heading>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
            This is a short overview of the LinkedIn Jobs statistics for December
            2022. The data is based on the LinkedIn Jobs collected by various search queries.
            If a job posting appeared in multiple search queries, it was included into the
            several categories.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
            <Tag size={'md'} variant="solid" colorScheme="teal">
              {techdeps.__metadata.total_results}
            </Tag>&nbsp;job postings were collected for analysis using the queries
            <BlogTags tags={techdeps.__metadata.queries.slice(0, 5)} />
            {techdeps.__metadata.queries.length > 5 && <>
              and {techdeps.__metadata.queries.length - 5} more
            </>}
          </Text>
          <BlogAuthor name="Mike Tulubaev" link="https://www.linkedin.com/in/mikhail-tyulyubaev-4a12a21b4/" date={new Date('2023-05-01T13:00:00Z')} />
        </Box>
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center">
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%">
              {/* <ExternalButton link='https://google.com'>
                Explore full dataset
              </ExternalButton> */}
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(orange.600 1px, transparent 1px)',
                'radial(orange.300 1px, transparent 1px)'
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
      </Box>

      <TechDepsChart />
      <Divider marginTop="12" marginBottom="12" />
      <TechChart />
      <Divider marginTop="12" marginBottom="12" />
      <CountryChart />
    </Container>
  );
};

export default ArticleList;
