import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Heading,
    Text,
    VStack,
} from '@chakra-ui/react';
import {
    Chart as ChartJS,
} from 'chart.js';
import { SankeyController, Flow } from 'chartjs-chart-sankey';

import { Bar } from 'react-chartjs-2';
import techdeps from '../data/tech_dependencies.json';
import country_level from '../data/country_seniority_levels.json';

ChartJS.register(SankeyController, Flow);

const toArray = (data: any) => {
    return Object.keys(data)
        .filter(key => key !== '__metadata')
        .map(key => ({
            name: key,
            data: data[key]
        }));
}

const countryDataArray = toArray(country_level);

const countryLabels = countryDataArray.map((country) => country.name);
const countryDataSets = [
    {
        label: 'Junior',
        data: countryDataArray.map((country) => country.data.junior),
        backgroundColor: '#1EE6B6',
    },
    {
        label: 'Middle',
        data: countryDataArray.map((country) => country.data.middle),
        backgroundColor: '#FFC633',
    },
    {
        label: 'Senior',
        data: countryDataArray.map((country) => country.data.senior),
        backgroundColor: '#A12FFC',
    }
]

const colorPalette = [
    "#bd0f0f",
    "#5b41a3",
    "#0099ff",
    "#ffcc00",
    "#66cccc",
    "#99cc00",
    "#993399",
    "#b23333",
    "#077861",
    "#0073bf",
    "#bf9900",
    "#4c9999",
    "#739900",
    "#732673",
]

const colors = {
    a: 'red',
    b: 'green',
    c: 'blue',
    d: 'gray'
};

// @ts-ignore
const colorFactory = () => {
    const state: any = {};
    let index = 0;

    return (key: any) => {
        if (key in state) {
            return state[key];
        }

        state[key] = colorPalette[index];
        index += 1;
        if (index >= colorPalette.length) {
            index = 0;
        }

        return state[key];
    }
}
const getColor = colorFactory();

const TechDepsChart = () => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    const [keys, setKeys] = useState<(keyof typeof techdeps)[]>(['net',
        'java',
        'angular',
        'react',
        'nodejs',
        'python',
        'php']);

    useEffect(() => {
        const chartElem = chartRef.current!;
        const ctx = chartElem;

        const linkedData = keys.map((key) => {
            const data = techdeps[key];
            return {
                from: key,
                to: Object.keys(data)
                    // take only top 10 technologies
                    .filter(x => !(keys.includes(x as any)))
                    // @ts-ignore
                    .sort((a, b) => data[b] - data[a])
                    .slice(0, 10)
                    .map(x => ({
                        to: x,
                        // @ts-ignore
                        flow: Math.ceil((+data[x]) * 100)
                    }))
            }
        }).reduce((acc, x) => {
            const itemData = x.to.reduce((intAcc, intX) => {
                const newItem = {
                    from: x.from,
                    to: intX.to,
                    flow: intX.flow
                };
                return [
                    ...intAcc,
                    newItem,
                ];
            }, [] as any[]);

            return [
                ...acc,
                ...itemData
            ]
        }, [] as any[]);

        const existingChart = ChartJS.getChart(ctx);
        if (existingChart) {
            existingChart.destroy();
        }

        new ChartJS(ctx, {
            type: 'sankey',
            data: {
                datasets: [
                    {
                        label: 'Technologies',
                        data: linkedData,
                        colorFrom: (c) => getColor(c.dataset.data[c.dataIndex].from),
                        colorTo: (c) => getColor(c.dataset.data[c.dataIndex].to),
                        colorMode: 'gradient',
                        size: 'max',
                        labels: {
                            nodejs: 'NodeJS',
                            net: '.net/.net core',
                            react: 'ReactJS',
                            angular: 'Angular'
                        },
                    }
                ]
            }
        });

    }, [keys]);


    return <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Heading as="h2">Technology Dependencies</Heading>
        <Text as="p" fontSize="lg">
            The chart below shows the dependencies between technologies. The links are calculated based
            on the common tags in the job descriptions. The weight of the link is calculated based on
            the number of job descriptions with the same tags.
        </Text>
        <Box width="100%">
            <canvas id="sankey" ref={chartRef}></canvas>
        </Box>
    </VStack>
}

export default TechDepsChart;
