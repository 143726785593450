import React, { useEffect, useState } from 'react';
import {
    Box,
    Heading,
    Text,
    VStack,
} from '@chakra-ui/react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import country_level from '../data/country_seniority_levels.json';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const toArray = (data: any) => {
    return Object.keys(data)
        .filter(key => key !== '__metadata')
        .map(key => ({
            name: key,
            data: data[key]
        }));
}

const countryDataArray = toArray(country_level);

const countryLabels = countryDataArray.map((country) => country.name);
const countryDataSets = [
    {
        label: 'Junior',
        data: countryDataArray.map((country) => country.data.junior),
        backgroundColor: '#1EE6B6',
    },
    {
        label: 'Middle',
        data: countryDataArray.map((country) => country.data.middle),
        backgroundColor: '#FFC633',
    },
    {
        label: 'Senior',
        data: countryDataArray.map((country) => country.data.senior),
        backgroundColor: '#A12FFC',
    }
]

const CountryChart = () => {
    const [countryRefData, setCountryRefData] = useState<any>();

    useEffect(() => {
        const data = countryDataSets.map((country) => country.data);

        const emptyData = countryLabels.map((label, index) => {
            const sumData = data.reduce((acc, val) => acc + val[index], 0);

            return {
                index,
                sum: sumData
            }
        }).filter(x => x.sum === 0)

        const emptyIdxs = emptyData.map((val) => val.index);

        setCountryRefData({
            labels: countryLabels.filter((_, index) => !emptyIdxs.includes(index)),
            datasets: countryDataSets.map(x => ({
                ...x,
                data: x.data.filter((_, index) => !emptyIdxs.includes(index))
            }))
        })

    }, []);


    return <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Heading as="h2">Level by Country</Heading>
        <Text as="p" fontSize="lg">
            The following chart represents the required number of experience sliced by country.
        </Text>
        <Text as="p" fontSize="lg">
            The required level is extracted from the job description using the keywords, in the case if
            the level is not specified in the job description, the level is calculated based on the
            required number of years of experience.
        </Text>
        <Box width="100%">
            {countryRefData && <Bar
                options={{
                    responsive: true,
                }}
                data={countryRefData}>
            </Bar>}
        </Box>
    </VStack>
}

export default CountryChart;
